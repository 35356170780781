import {
  Box,
  SimpleGrid,
  Skeleton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
  Button,
  useToast,
} from "@chakra-ui/react";
import Layout from "../../Components/Layout";
import { RootState } from "../../Store/types";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFile } from "./useFile";
import { PlayerInfo } from "../../Components/PlayerInfo";
import { deleteFile, getFile, sendFile } from "../../Services/file";
import { useState } from "react";

export const File: React.FC = () => {
  const navigate = useNavigate();
  const { season, user } = useSelector((state: RootState) => state);
  const toast = useToast();
  const { files, loading, setLoading, handleFileData } = useFile();

  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadDate, setUploadDate] = useState<string>("");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isUploadOpen,
    onOpen: onUploadOpen,
    onClose: onUploadClose,
  } = useDisclosure();

  const fetchAndDisplayImage = async (fileId: number) => {
    const fileBlob = await getFile(fileId);

    if (fileBlob) {
      const imageUrl = URL.createObjectURL(fileBlob);
      setImageUrl(imageUrl);
      onOpen();
    }
  };

  const handleFileUpload = async () => {
    if (selectedFile && uploadDate) {
      setLoading(true);
      const result = await sendFile(
        selectedFile,
        uploadDate,
        season.data?.id ?? 0
      );
      if (!result) {
        toast({
          title: "Erro",
          description: "Ocorreu um erro ao subir a foto",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setLoading(false);
        onUploadClose();
        return;
      }
      await handleFileData(season.data?.id ?? 0);
      onUploadClose();
      setLoading(false);
    } else {
      toast({
        title: "Erro",
        description: "Informe todos os campos",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const formatDateToBrazilian = (date: string) => {
    const parsedDate = new Date(`${date}T00:00:00`);
    return new Intl.DateTimeFormat("pt-BR").format(parsedDate);
  };

  const handleRemove = async (itemId: number) => {
    setItemToRemove(itemId);
    onConfirmRemoveOpen();
  };

  const [itemToRemove, setItemToRemove] = useState<number | null>(null);

  const confirmRemove = async () => {
    if (itemToRemove) {
      setLoading(true);
      const result = await deleteFile(itemToRemove);
      if (result) {
        toast({
          title: "Sucesso",
          description: "Arquivo removido com sucesso",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Sucesso",
          description: "Erro ao remover arquivo",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
      onConfirmRemoveClose();
      await handleFileData(season.data?.id ?? 0);
      setLoading(false);
    }
  };

  const {
    isOpen: isConfirmRemoveOpen,
    onOpen: onConfirmRemoveOpen,
    onClose: onConfirmRemoveClose,
  } = useDisclosure();

  return (
    <Layout
      title="Arquivos"
      onBack={() => navigate(-1)}
      add={user.user?.admin ? onUploadOpen : undefined}
    >
      <Box
        display="flex"
        flexDirection="column"
        minH="100vh"
        p={4}
        overflow="hidden"
      >
        <Box
          flex="1"
          maxW="6xl"
          w="full"
          borderRadius="md"
          boxShadow="lg"
          overflowY="auto"
        >
          {loading && (
            <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing={4}>
              {[...Array(6)].map((_, index) => (
                <Skeleton key={index} height="150px" borderRadius="md" />
              ))}
            </SimpleGrid>
          )}
          {!loading && files && files.length > 0 && (
            <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing={4}>
              {files?.map((file) => (
                <Box
                  key={file.id}
                  borderRadius="md"
                  boxShadow="md"
                  bg={"black"}
                >
                  <PlayerInfo
                    name={formatDateToBrazilian(file?.data)}
                    photoUrl={""}
                    add={() => {}}
                    playerId={file.id}
                    seandonId={season.data?.id ?? 0}
                    view={fetchAndDisplayImage}
                    admin={false}
                    iconRemove={!!(user.user?.admin ?? false)}
                    remove={handleRemove}
                  />
                </Box>
              ))}
            </SimpleGrid>
          )}
        </Box>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Visualizar Arquivo</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {imageUrl && (
              <img
                src={imageUrl}
                alt="Arquivo"
                style={{ width: "100%", height: "auto" }}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={isUploadOpen} onClose={onUploadClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Upload de Arquivo</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl id="uploadDate" mb={4}>
              <FormLabel>Data</FormLabel>
              <Input
                type="date"
                value={uploadDate}
                onChange={(e) => setUploadDate(e.target.value)}
              />
            </FormControl>

            <FormControl id="fileUpload" mb={4}>
              <FormLabel>Upload de Foto</FormLabel>
              <Input
                type="file"
                accept="image/*"
                onChange={(e) => setSelectedFile(e.target.files?.[0] || null)}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button onClick={handleFileUpload} variant="ghost">
              Enviar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isConfirmRemoveOpen} onClose={onConfirmRemoveClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmar Remoção</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Você tem certeza que deseja remover este arquivo?
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" onClick={confirmRemove}>
              Remover
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Layout>
  );
};
