import { UserState } from "../Store/features/userSlice";
import api from "./api";

export interface TopScorersResponse {
  message: string;
  data: TopScorer[];
}

interface TopScorer {
  user_id: number;
  user_name: string;
  user_photo: string;
  total_goals: string;
  user_photo_base64: string;
}

export interface Goal {
  id: number;
  goal: number;
  victory: number;
  date: string;
  user_id: number;
  season_id: number;
  own_goal: number;
}

export interface User {
  id: number;
  name: string;
  login: string;
  admin: number; // Use boolean se preferir (0 ou 1)
  photo: string; // URL ou string vazia
  goals: Goal[];
}

export interface PlayerHistoryResponse {
  user_name: string;
  goals: Goal[];
}

export interface SeasonUsersResponse {
  users: User[];
}

export interface PlayerStats {
  user_name: string;
  max_goals: number;
  average_goals: number;
  matches_played: number;
  total_own_goals: number;
  net_goals: number;
}

export const getPlayers = async (
  seasonId: number
): Promise<TopScorersResponse | undefined> => {
  try {
    const response = await api.get<TopScorersResponse>(
      `/objectives/top-score/${seasonId}`
    );
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar jogadores:", error);
    return undefined;
  }
};

export const getPlayer = async (
  userId: number,
  seasonId: number
): Promise<UserState | undefined> => {
  try {
    const response = await api.get<UserState>(`/user/${userId}/${seasonId}`);
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar dados do jogador:", error);
    return undefined;
  }
};

export const updateUserPhoto = async (
  userId: number,
  photo: File
): Promise<void> => {
  try {
    const formData = new FormData();
    formData.append("photo", photo);

    await api.post(`/user/${userId}/update-photo`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error) {
    console.error("Erro ao atualizar a foto do usuário:", error);
  }
};

export const getPlayersBySeason = async (
  seasonId: number
): Promise<SeasonUsersResponse | undefined> => {
  try {
    const response = await api.get<SeasonUsersResponse>(
      `/users/season/${seasonId}`
    );
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar jogadores por temporada:", error);
    return undefined;
  }
};

export const getPlayersBySeasonToken = async (
  seasonId: number
): Promise<UserState | undefined> => {
  try {
    const response = await api.get<UserState>(`/user-by-season/${seasonId}`);
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar jogadores por temporada com token:", error);
    return undefined;
  }
};

export const getPlayersHistory = async (
  seasonId: number,
  playerId: number
): Promise<PlayerHistoryResponse | undefined> => {
  try {
    const response = await api.get<PlayerHistoryResponse>(
      `/users/${playerId}/season/${seasonId}/goals`
    );
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar jogadores por temporada com token:", error);
    return undefined;
  }
};

export const newPlayer = async (
  login: string,
  password: string,
  name: string
): Promise<boolean> => {
  try {
    await api.post(`/user`, { name, login, password, admin: false });
    return true;
  } catch (error) {
    console.error("Erro ao cadastrar novo jogador:", error);
    return false;
  }
};

export const getRecords = async (
  userId: number
): Promise<PlayerStats | undefined> => {
  try {
    const response = await api.get<PlayerStats>(`/user-status/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Erro ao consultar recordes do jogador:", error);
    return undefined;
  }
};
