import React from "react";
import {
  Box,
  Image,
  Text,
  HStack,
  VStack,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";
import { FaPlus, FaEye, FaTrash } from "react-icons/fa";

interface PlayerInfoInterface {
  photoUrl: string;
  name: string;
  playerId: number;
  add: (playerId: number, namePlayer: string) => void;
  view: (playerId: number, seasonId: number, name: string) => void;
  seandonId: number;
  admin: boolean;
  remove?: (itemId: number) => void;
  iconRemove?: boolean;
}

export const PlayerInfo: React.FC<PlayerInfoInterface> = ({
  photoUrl,
  name,
  playerId,
  add,
  view,
  seandonId,
  admin = false,
  iconRemove = false,
  remove,
}) => {
  const isBase64Image = (url: string) => {
    return /^data:image\/(png|jpeg|jpg|gif|webp);base64,/.test(url);
  };

  const displayPhotoUrl = isBase64Image(photoUrl) ? photoUrl : "/logo192.png";

  const navigationButtons = [
    {
      icon: FaPlus,
      label: "Add",
      action: () => add(playerId, name),
      visible: admin,
    },
    {
      icon: FaEye,
      label: "View",
      action: () => view(playerId, seandonId, name),
      visible: true,
    },
    {
      icon: FaTrash,
      label: "remove",
      action: () => remove && remove(playerId),
      visible: iconRemove,
    },
  ];

  return (
    <Box w="100%" p={2} borderRadius="md" boxShadow="md" textAlign="center">
      <HStack spacing={4} align="center">
        <Image
          borderRadius="full"
          boxSize="50px"
          src={displayPhotoUrl}
          alt={`${name} photo`}
        />
        <VStack align="start" spacing={1} flex="1">
          <Text fontSize="xl" fontWeight="bold">
            {name}
          </Text>
        </VStack>
        <HStack spacing={2}>
          {navigationButtons
            .filter((button) => button.visible)
            .map((button, index) => (
              <Tooltip key={index} label={button.label}>
                <IconButton
                  bg={"#1a8a1a"}
                  aria-label={button.label}
                  icon={<button.icon color={"white"} />}
                  size="sm"
                  onClick={(e) => {
                    e.stopPropagation();
                    button.action();
                  }}
                />
              </Tooltip>
            ))}
        </HStack>
      </HStack>
    </Box>
  );
};
