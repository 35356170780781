import api from "./api";

export const newGoal = async (
  goal: number,
  victory: number,
  date: string,
  user_id: number,
  season_id: number,
  own_goal: number
): Promise<boolean | undefined> => {
  try {
    await api.post("/objectives", {
      goal,
      victory,
      date,
      user_id,
      season_id,
      own_goal,
    });
    return true;
  } catch (error) {
    console.log(error);
  }
};

export const updateGoal = async (
  goal: number,
  victory: number,
  date: string,
  user_id: number,
  season_id: number,
  id: number,
  own_goal: number
): Promise<boolean | undefined> => {
  try {
    await api.put(`/objectives/${id}`, {
      goal,
      victory,
      date,
      user_id,
      season_id,
      own_goal,
    });
    return true;
  } catch (error) {
    console.log(error);
  }
};
