import {
  Box,
  SimpleGrid,
  Skeleton,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import Layout from "../../Components/Layout";
import { usePlayers } from "./usePlayers";
import { PlayerInfo } from "../../Components/PlayerInfo";
import { RootState } from "../../Store/types";
import { useDispatch, useSelector } from "react-redux";
import {
  ObjectiveData,
  RegisterObjectiveModal,
} from "../../Components/RegisterObjectiveModal";
import { useState } from "react";
import { newGoal } from "../../Services/objective";
import { useNavigate } from "react-router-dom";
import { routes } from "../../Routes/routers";
import { setUserSelected } from "../../Store/features/userSlice";

export const Players: React.FC = () => {
  const { loading, players, setLoading } = usePlayers();
  const [playerId, setPlayerId] = useState(0);
  const [namePlayer, setNamePlayer] = useState("");

  const { season, user } = useSelector((state: RootState) => state);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleSelectPlayer = async (
    playerIdSelected: number,
    namePlayer: string
  ) => {
    if (user.user?.admin) {
      setPlayerId(playerIdSelected);
      setNamePlayer(namePlayer);
      onOpen();
    }
  };

  const handleRegisterObjective = async (data: ObjectiveData) => {
    setLoading(true);
    const reponse = await newGoal(
      data.goal,
      data.victory,
      data.date,
      data.userId,
      data.seasonId,
      data.own_goal
    );
    if (!reponse) {
      toast({
        title: "Erro",
        description: "Ocorreu um erro ao salvar.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Sucesso",
        description: "Contabilizado!",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
    setLoading(false);
  };

  const handleViewStory = (
    playerId: number,
    seasonId: number,
    name: string
  ) => {
    dispatch(
      setUserSelected({
        id: playerId,
        season: seasonId,
        name,
      })
    );
    navigate(routes.playerHistory);
  };

  const handleConfiguration = async () => {
    navigate(routes.files);
  };

  return (
    <Layout title="Jogadores" onClick={handleConfiguration}>
      <RegisterObjectiveModal
        key={playerId}
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleRegisterObjective}
        seasonId={season.data?.id ?? 0}
        userId={playerId}
        name={namePlayer}
      />
      <Box
        display="flex"
        flexDirection="column"
        minH="100vh"
        p={0}
        overflow="hidden"
      >
        <Box
          flex="1"
          maxW="6xl"
          w="full"
          p={4}
          borderRadius="md"
          boxShadow="lg"
          overflowY="auto"
        >
          {loading && (
            <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing={4}>
              {[...Array(6)].map((_, index) => (
                <Skeleton key={index} height="150px" borderRadius="md" />
              ))}
            </SimpleGrid>
          )}
          {!loading && players?.users && players.users.length > 0 && (
            <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing={4}>
              {players?.users?.map((player) => (
                <Box
                  key={player.id}
                  borderRadius="md"
                  boxShadow="md"
                  bg={"black"}
                >
                  <PlayerInfo
                    name={player?.name}
                    photoUrl={player?.photo}
                    add={handleSelectPlayer}
                    playerId={player.id}
                    seandonId={season.data?.id ?? 0}
                    view={handleViewStory}
                    admin={!!(user.user?.admin ?? false)}
                  />
                </Box>
              ))}
            </SimpleGrid>
          )}
        </Box>
      </Box>
    </Layout>
  );
};
