import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getPlayer } from "../../Services/home";
import { setUserDetails, UserState } from "../../Store/features/userSlice";
import { RootState } from "../../Store/types";

export const usePlay = () => {
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const dispatch = useDispatch();

  const { season, user } = useSelector((state: RootState) => state);

  const handleGetPlay = async () => {
    const maxRetries = 3;
    let attempts = 0;

    while (attempts < maxRetries) {
      try {
        setLoading(true);
        const response: UserState | undefined = await getPlayer(
          user?.user?.id ?? 0,
          season.data?.id ?? 0
        );

        if (response) {
          dispatch(
            setUserDetails({
              user: response.user,
              total_goals: response.total_goals,
              goal_count: response.goal_count,
            })
          );
        }
        return;
      } catch (error) {
        attempts++;

        if (attempts >= maxRetries) {
          toast({
            title: "Erro",
            description:
              "Erro ao carregar os dados do jogador após várias tentativas.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return {
    setLoading,
    loading,
    handleGetPlay,
  };
};
