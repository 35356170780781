import { useSelector } from "react-redux";
import { FileData, getFileData } from "../../Services/file";
import { RootState } from "../../Store/types";
import { useEffect, useState } from "react";

export const useFile = () => {
  const { season } = useSelector((state: RootState) => state);
  const [loading, setLoading] = useState(true);
  const [files, setFiles] = useState<FileData[]>();

  const handleFileData = async (seasonId: number) => {
    setLoading(true);
    const response = await getFileData(seasonId);
    if (response) {
      setFiles(response);
    } else {
      setFiles([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (season.data?.id) {
      handleFileData(season.data.id);
    }
  }, [season]);

  return {
    loading,
    files,
    setLoading,
    handleFileData,
  };
};
