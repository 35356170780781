import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../Routes/routers";
import { login } from "../../Services/login";
import { useToast } from "@chakra-ui/react";

export const useLoginScreen = () => {
  const [cellPhone, setCellPhone] = useState("");
  const [password, setPassword] = useState("");

  const [playerName, setPlayerName] = useState("");
  const [playerLogin, setPlayerLogin] = useState("");
  const [playerPassword, setPlayerPassword] = useState("");

  const navigate = useNavigate();
  const toast = useToast();

  const handleCellPhoneChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCellPhone(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handlePlayerName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPlayerName(event.target.value);
  };

  const handlePlayerLogin = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPlayerLogin(event.target.value);
  };

  const handlePlayerPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPlayerPassword(event.target.value);
  };

  const goToHome = async () => {
    try {
      const response = await login(cellPhone, password);
      localStorage.setItem("jwt_token", response.token);
      navigate(routes.home);
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message || "Ocorreu um erro inesperado.";

      toast({
        title: "Erro",
        description: errorMessage,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return {
    playerName,
    cellPhone,
    password,
    playerLogin,
    playerPassword,
    handleCellPhoneChange,
    handlePasswordChange,
    goToHome,
    handlePlayerName,
    handlePlayerLogin,
    handlePlayerPassword,
    setCellPhone,
    setPassword,
  };
};
