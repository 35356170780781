import React from "react";
import { Box, Image, Text, HStack, VStack } from "@chakra-ui/react";

interface PlayerCardProps {
  photoUrl: string;
  name: string;
  goals: number;
  textGoal: string;
}

export const PlayerCard: React.FC<PlayerCardProps> = ({
  photoUrl,
  name,
  goals,
  textGoal,
}) => {
  const isBase64Image = (url: string) => {
    return /^data:image\/(png|jpeg|jpg|gif|webp);base64,/.test(url);
  };

  const displayPhotoUrl = isBase64Image(photoUrl) ? photoUrl : "/logo192.png";

  return (
    <Box
      w="100%"
      p={2}
      borderRadius="md"
      boxShadow="md"
      bg={"black"}
      textAlign="center"
    >
      <HStack spacing={4} align="center">
        <Image
          borderRadius="full"
          boxSize="50px"
          src={displayPhotoUrl}
          alt={`${name} photo`}
        />
        <VStack align="start" spacing={1}>
          <Text fontSize="xl" fontWeight="bold">
            {name}
          </Text>
          <Text fontSize="md">
            {textGoal}: {goals}
          </Text>
        </VStack>
      </HStack>
    </Box>
  );
};
