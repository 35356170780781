import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react";

interface RegisterObjectiveModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: ObjectiveData) => void;
  userId: number;
  seasonId: number;
  name: string;
  idEdit?: boolean;
  goal?: number;
  victory?: number;
  own_goal?: number;
  date?: string;
  id?: number;
}

export interface ObjectiveData {
  goal: number;
  victory: number;
  own_goal: number;
  date: string;
  userId: number;
  seasonId: number;
  id?: number;
}

export const RegisterObjectiveModal: React.FC<RegisterObjectiveModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  seasonId,
  userId,
  name,
  date = "",
  goal,
  victory,
  own_goal,
  id = 0,
  idEdit = false,
}) => {
  const toast = useToast();

  const getCurrentDate = (): string => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };

  const [formData, setFormData] = useState<{
    goal: string;
    victory: string;
    own_goal: string;
    date: string;
  }>({
    goal: "",
    victory: "",
    own_goal: "",
    date: getCurrentDate(),
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    if (
      !formData.date ||
      formData.goal === "" ||
      formData.victory === "" ||
      formData.own_goal === ""
    ) {
      toast({
        title: "Erro",
        description: "Por favor, preencha todos os campos obrigatórios.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    onSubmit({
      goal: Number(formData.goal),
      victory: Number(formData.victory),
      own_goal: Number(formData.own_goal),
      date: formData.date,
      userId,
      seasonId,
      id,
    });
    onClose();
  };

  useEffect(() => {
    if (isOpen && !idEdit) {
      setFormData({
        goal: "",
        victory: "0",
        own_goal: "0",
        date: getCurrentDate(),
      });
    }
  }, [isOpen, idEdit]);

  useEffect(() => {
    if (idEdit) {
      setFormData({
        goal: String(goal),
        victory: String(victory),
        own_goal: String(own_goal),
        date: date,
      });
    }
  }, [idEdit, goal, victory, own_goal, date]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Como {name} se saiu?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mb={4}>
            <FormLabel>Gols</FormLabel>
            <Input
              type="number"
              name="goal"
              value={formData.goal}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Gols Contra</FormLabel>
            <Input
              type="number"
              name="own_goal"
              value={formData.own_goal}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Vitórias</FormLabel>
            <Input
              type="number"
              name="victory"
              value={formData.victory}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Data</FormLabel>
            <Input
              type="date"
              name="date"
              value={formData.date}
              onChange={handleChange}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
            Salvar
          </Button>
          <Button onClick={onClose}>Cancelar</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
